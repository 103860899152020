const API_WEB = new Object();
API_WEB.config = {
  xhr: {
    XHR_CACHED: false,
    // crossDomain: true,
    XHR_TIMEOUT: 30000
  },
  api_modules: {
    top: {
      1: "https://dev.suzaka-marugotomuseum.jp/wp-json/api/topcarousel",
      2: "https://dev.suzaka-marugotomuseum.jp/wp-json/api/pickup",
      3: ""
    },
    cultural: {
      // 1: "https://city-suzaka-nagano.sjdev.jp/api_culturalassets/",
      1: "https://dev.suzaka-marugotomuseum.jp/api_culturalassets_sub/",
      // 2: "https://dev.suzaka-marugotomuseum.jp/api_culturalassets_search/",
      2: "https://dev.suzaka-marugotomuseum.jp/api_culturalassets_search_sub/",
      3: "https://dev.suzaka-marugotomuseum.jp/api_culturalassets_id/"
    },
    taiken: {
      1: "https://dev.suzaka-marugotomuseum.jp/wp-json/api/experience/"
      // 1: "https://city-suzaka-nagano.sjdev.jp/wp-json/api/experience/"
    },
    publish: {
      1: "https://dev.suzaka-marugotomuseum.jp/wp-json/api/publications"
    },
    news: {
      1: "https://dev.suzaka-marugotomuseum.jp/wp-json/api/news"
    }
  }
};

/**
 *
 * API
 *
 * M1 post
 *
 */
// API_WEB.M1 = (dat, page, type) => {
//   let dfd = new $.Deferred();
//   $.ajax(
//     "https://" + location.hostname + API_WEB.config.api_modules[page][type],
//     {
//       cache: API_WEB.config.xhr.XHR_CACHED,
//       data: JSON.stringify(dat),
//       dataType: "JSON",
//       type: "POST",
//       timeout: API_WEB.config.xhr.XHR_TIMEOUT
//     }
//   ).then(
//     function(data) {
//       if (data.status) {
//         dfd.resolve(data);
//       } else {
//         dfd.reject(data);
//       }
//     },
//     function(jqXHR) {
//       dfd.reject(jqXHR);
//     }
//   );
//   return dfd.promise();
// };

/**
 *
 * API
 *
 * M2 GET
 *
 */
API_WEB.M2 = (page, type, param) => {
  let dfd = new $.Deferred();
  $.ajax(
    // "https://" +
    //   location.hostname +
    API_WEB.config.api_modules[page][type] + (param == "" ? "" : "?" + param),
    {
      cache: API_WEB.config.xhr.XHR_CACHED,
      // data: JSON.stringify(dat),
      dataType: "JSON",
      type: "GET",
      timeout: API_WEB.config.xhr.XHR_TIMEOUT
      // crossDomain: API_WEB.config.xhr.crossDomain
    }
  ).then(
    function(data) {
      dfd.resolve(data);
      // if (data.status) {
      //   dfd.resolve(data);
      // } else {
      //   dfd.reject(data);
      // }
    },
    function(jqXHR) {
      dfd.reject(jqXHR);
    }
  );
  return dfd.promise();
};

API_WEB.M3 = (page, type, param) => {
  let dfd = new $.Deferred();
  $.ajax(
    // "https://" +
    //   location.hostname +
    API_WEB.config.api_modules[page][type] + param,
    {
      cache: API_WEB.config.xhr.XHR_CACHED,
      // data: JSON.stringify(dat),
      dataType: "JSON",
      type: "GET",
      timeout: API_WEB.config.xhr.XHR_TIMEOUT
      // crossDomain: API_WEB.config.xhr.crossDomain
    }
  ).then(
    function(data) {
      dfd.resolve(data);
      // if (data.status) {
      //   dfd.resolve(data);
      // } else {
      //   dfd.reject(data);
      // }
    },
    function(jqXHR) {
      dfd.reject(jqXHR);
    }
  );
  return dfd.promise();
};

export default API_WEB;
window.API_WEB = API_WEB;
